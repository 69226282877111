$corporate-blue: #0074bd;
$corporate-blue-t1: #99c7e4;
$corporate-blue-t2: #66abd7;
$corporate-blue-t3: #338fca;
$corporate-blue-t4: #0064a4;
$corporate-blue-t5: #004b7b;

$orange: #ff5427;
$orange-t1: #ffe5de;
$orange-t2: #ffc3b3;
$orange-t3: #ff7e5d;
$orange-t4: #cc431f;
$orange-t5: #993217;