body {
  overflow-x: hidden;

  &.dialogue-open {
    overflow: hidden;
  }
}

i {
  font-style: italic;
}

sub {
  vertical-align: sub;
}

sub, sup {
  font-size: smaller;
}

sup {
  vertical-align: super;
}

.mce-edit-focus {
  border: 0px !important;
  outline: none !important;
}

.question-container {
  scrollbar-color: rgba(0,0,0,0.29) transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.29);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track-piece  {
    background-color: transparent;
  }
}