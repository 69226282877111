@import "../../assets/stylesheets/utils/colours";

.dropzone {
  align-items: center;
  border: 3px dashed #e0e0e0;
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  min-height: inherit;
  outline: none;
  padding: 20px;
  position: relative;

  &:focus {
    border-color: $corporate-blue;
  }

  &.has-files {
    align-items: flex-start;
  }
}